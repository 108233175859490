<template>
  <v-container class="container--fluid fill-height primary">
    <v-row
      no-gutters
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
        lg="4"
      >
        <v-card class="elevation-5 pa-3">
          <v-card-text>
            <div class="layout column align-center">
              <h1 class="text-center my-4 primary--text">
                DataSmarty {{ $t("login.singupForm") }}
              </h1>
            </div>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-text-field
                v-model="company_name"
                append-icon="mdi-account"
                name="company_name"
                :label="$t('login.company_name')"
                type="text"
                required
              />

              <v-text-field
                v-model="firstName"
                append-icon="mdi-account"
                name="firstname"
                :label="$t('login.firstname')"
                type="text"
                required
                :rules="firstNameRules"
              />

              <v-text-field
                v-model="lastname"
                append-icon="mdi-account"
                name="lastname"
                :label="$t('login.lastname')"
                type="text"
                required
                :rules="lastNameRules"
              />

              <v-text-field
                v-model="username"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"

                name="email"
                :label="$t('login.email')"
                :type="show2 ? 'text' : 'password'"
                required
                :rules="emailRules"
                @click:append="show2 = !show2"
              />
              <v-text-field
                v-model="newPassword"
                :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"

                :type="show3 ? 'text' : 'password'"
                name="password"
                :label="$t('login.password')"
                required

                autocomplete="new-password"
                @click:append="show3 = !show3"
              />
              <v-text-field
                v-model="confirmPassword"
                append-icon="mdi-lock"
                name="passwordconfirm"
                :label="$t('login.passwordconfirm')"
                type="password"
                required
                autocomplete="new-password"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              outlined
              to="/singin"
            >
              {{ $t("login.singIn") }}
            </v-btn>

            <v-btn
              color="primary"
              :loading="loading"
              @click="validate"
            >
              {{ $t("login.singUp") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import request from '@/api/utils';
import Localization from '../widget/AppLocalization.vue';

export default {
  name: 'SingUp',
  components: { Localization },
  data: () => ({
    loading: false,
    rules: {
      required: (value) => !!value || 'Current Password is required.',
    },
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    gsmRules: [
      (v) => !!v || 'Gsm is required',
      (v) => (v && v.length <= 11) || 'Gsm must 11 characters',
    ],
    firstNameRules: [
      (v) => !!v || 'Firstname is required',
      (v) => (v && v.length <= 50) || 'Firtsname must be less than 50 characters',
    ],
    lastNameRules: [
      (v) => !!v || 'Lastname is required',
      (v) => (v && v.length <= 50) || 'Lastname must be less than 50 characters',
    ],
    valid: true,
    selectUserRole: '',
    username: '',
    firstName: '',
    lastName: '',
    gsm: '',
    email: '',
    newPassword: '',
    confirmPassword: '',
    show3: false,
    show2: false,
  }),
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        const params = {
          username: this.username,
          firstname: this.firstName,
          lastname: this.lastname,
          status: 'Acitive',
          password: this.newPassword,
          passwordconfirm: this.confirmPassword,
          userRole: 'Manager',
        };

        request.Api('/panel/user/create', params, 'post').then((res) => {
          console.log('res');
          console.log(res);
        });
      }
    },
    login() {
      this.loading = true;
      setTimeout(() => {
        this.$router.push('/dashboard');
      }, 1000);
    },
  },
};
</script>
